import React from "react"
import Layout from "../../components/Layout"
import PageMoved from "../../components/PageMoved"
import { graphql } from "gatsby"
import { withLocation } from "../../util"
import { navigate } from "../../components/IntlLink"
import queryString from 'query-string'

function NotFound({ location, params, pageContext: { language }, data: { members } }) {
  
	// console.log(location, params)
	// console.log(language)
	// http://localhost:8000/404/?404;http://localhost:8000/list_of_members/?mid=8483&type=abridged
	
	let tmp
	let newLocation
	let showRedirect = false
	let orgPath

	// console.log(Object.keys(params)[0])

	if (Object.keys(params).length > 0 && /^404;/.test(Object.keys(params)[0])) {
		// in IIS 404
		const orgUrl = queryString.stringify(params, {encode: false}).split(';')[1].replace(/.*?\/\/.*?\//, "/")
		orgPath = orgUrl.replace(/\?.*/, "")
		params = queryString.parse(orgUrl.replace(/.*\?/, "?"))
		window.history.replaceState(null, "", orgPath)
	}
	else {
		orgPath = location.pathname
	}

	// const { members } = useRedirectQuery()
	const pathName = orgPath.replace(/^\/en/, "")

	// TODO: fix redirect path
	// uploaded files, especially PDFs
	if (/\/wp-content\//.test(pathName)) {
		if ((tmp = pathName.match(/\/pier_dp_(\d{3}).pdf/))) {
			newLocation = `/dp/${tmp[1]}/`
		}
		else if ((tmp = pathName.match(/\/PIERspectives_(\d{3}).pdf/))) {
			newLocation = `/pierspectives/${tmp[1]}/`
		}
		else if ((tmp = pathName.match(/\/aBRIDGEd_(\d{4})_(\d+).pdf/))) {
			newLocation = `/abridged/${tmp[1]}/${parseInt(tmp[2]).toString().padStart(2, "0")}`
		}
		else if ((tmp = pathName.match(/\/khao_tha(\d{3}).pdf/))) {
			newLocation = `/thaipublica/${tmp[1]}/`
		}
	}
	// members
	else if (pathName === "/list_of_members/") {

		const foundMember = members.nodes.filter(node => node.frontmatter.oldId === parseInt(params.mid))
		if (foundMember.length > 0) {
			newLocation = foundMember[0].fields.originalPath
		}

	}

	// anything below this point isn't that important and we should just redirect the user
	if (newLocation) {
		showRedirect = true
	}
	else {
		if (pathName.slice(0, 5) === "/tag/") {
			newLocation = `/search/?tags=${pathName.slice(5).replace(/\/$/, "")}`
		}
		else if (pathName.slice(0, 15) === "/abridged-date/") {
			newLocation = `/search/?collection=abridged&years=${params.y - 543}`
		}
		else if (pathName.slice(0, 16) === "/abridged-topic/") {
			newLocation = `/search/?collection=abridged&topics=${params.t.replace(/_/g, " ")}`
		}
		else if (pathName.slice(0, 17) === "/abridged-author/") {
			newLocation = `/search/?collection=abridged#authors`
		}
		else if (pathName.slice(0, 16) === "/abridged-reads/") {
			newLocation = `/search/?collection=abridged`
		}
		// stuff with collections/yyyy/zz/wrong-slug: try removing slug
		else if (tmp = pathName.match(/^(\/(?:abridged|exchanges|forums|seminars)\/\d{4}\/\d{2}\/).+$/)) {
			newLocation = tmp[1]
		}
		else if (tmp = pathName.match(/^(\/(?:announcements|blog)\/\d{4}\/\d{4}\/).+$/)) {
			newLocation = tmp[1]
		}
		else if (tmp = pathName.match(/^(\/(?:dp|pierpsectives|thaipublica)\/\d{3}\/).+$/)) {
			newLocation = tmp[1]
		}
		// this is a more extreme case, so should show notification
		// else if (tmp = pathName.match(/^(\/(?:abridged|exchanges|forums|seminars|workshops)\/)\d{4}\/\d{2}\/$/)) {
		// 	newLocation = tmp[1]
		// }
	}
	
	if (newLocation) {
		if (!showRedirect) {
			navigate(newLocation)
			return null
		}
		else {
			return <PageMoved newLocation={newLocation} origin={location.origin} lang={language} />
		}
	}

	// tag
	
	if (typeof window === 'undefined') {
		return null
	}

  return(
  	<Layout
			lang={language}
			title={"404: Not Found"}
			pathname={"/"}
			indexThis={false}
			shareThis={false}
		>
			{
				"Sorry. We can't find the page you're looking for."
			}
  	</Layout>
  )

}

export default withLocation(NotFound)

export const pageQuery = graphql`
	query NotFoundQuery {
		members: allMdx(filter: {fields: {collection: {eq: "members"}}}) {
			nodes {
				frontmatter {
					oldId
				}
				fields {
					originalPath
				}
			}
		}
	}
`